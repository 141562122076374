<style>
.dg-pop-photo{position: fixed;left: 0;top:0;right:0;bottom:0;z-index: 130;background: rgba(0,0,0, .4);display: flex;justify-content: center;align-items: center;transition: all 0.3s ease-in}
.dg-pop-photo .inner{position: relative;}
.dg-pop-photo .inner .btn-close{position: absolute;right:.5rem;top:.5rem;z-index: 131;background:url('../../../src/assets/front/images/icon/icon-close-03.png') rgba(255,255,255, .5) center center no-repeat;background-size: 10px auto;border-radius: 100%;width:1.6rem;height: 1.6rem;display: inline-block;font-size:0;line-height: 1;padding:.5rem;box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 15%);}
.dg-pop-photo img{max-width:  800px;max-height:400px;}
@media screen and (max-width: 1024px) {
  .pop-people-info-box .img-box .btn-photo-scale{top:110px;right:calc(50% - 55px);font-size:0;width:30px;height: 30px;padding:0;border-radius: 100%;background-color: #fff;border:1px solid #efefef}
  .pop-people-info-box .img-box .btn-photo-scale i{width:100%;height: 100%;background-size: 16px auto;margin: 0}
}
@media screen and (max-width: 640px) {
  .dg-pop-photo img{max-width: 90vw;max-height: 90vh;}
}
</style>
<template>
<div id="contents">
  <div class="dg-pop-layer pop-wd-wide st-poeple-dtl on" id="popPeopleDtlBox" v-if="popupTap=='detail'">
    <div class="dg-pop-hd-st2-box">
      <div class="pop-h1">회원정보</div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close" @click="closePopup">닫기</a></div>
    </div>
    <div class="pop-cont-box pop-people-info-box">
      <div class="img-box">
        <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../../src/assets/front/images/icon/member.jpg"/>
        <img class="member-list-box click" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"  @click="photoScale" />
        <button class="btn-photo-scale" @click="photoScale" ><i></i>사진 확대</button>
        <div class="btn-box"><a href="javascript:;" class="btnMemoSend" @click="popMessage()">쪽지 보내기</a></div><!-- btnMemoSend 스크립트 있음 -->
      </div>
      <div class="data-box">
        <div class="tb-01 st-w">
          <table>
            <colgroup><col width="140px"><col width="210px"><col width="140px"><col width="210px"></colgroup>
            <tbody>
            <tr>
              <th>이름</th>
              <td>{{ userDetail.name }}</td>
              <th>아이디</th>
              <td :style="userDetail.userId.length>15 ? `font-size:12px` : ''">{{ userDetail.userId }}</td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>{{ userDetail.birthDate }}</td>
              <th>휴대폰 번호</th>
              <td>{{ phoneFormatter(userDetail.mobileNo) }}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td colspan="3">{{ userDetail.email }}</td>
            </tr>
            <tr>
              <th>자택주소</th>
              <td colspan="3">{{ userDetail.address }} {{ userDetail.addressDetail }}</td>
            </tr>
            <tr>
              <th>현재 직장</th>
              <td>{{ userDetail.company }}</td>
              <th>현재 직책</th>
              <td>{{ userDetail.position }}</td>
            </tr>
            <tr>
              <th>최종부서<br>(전자 재임시)</th>
              <td>{{ userDetail.retirementDepartment }}</td>
              <th>직위<br>(전자 재임시)</th>
              <td>{{ userDetail.retirementPosition }}</td>
            </tr>
            <tr>
              <th>분과</th>
              <td colspan="3">{{ userDetailDepartment }}</td>
            </tr>
            <tr>
              <th>동호회</th>
              <td colspan="3">{{ userDetailClubs }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="dg-pop-photo" @click="photoDialog=false"
    v-if="photoDialog"
  >
    <div class="inner">
      <button class="btn-close">닫기</button>
      <img :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')" >
    </div>
  </div>
  
  <div class="dg-pop-layer pop-wd-wide st-memo-send on" id="popMemoSendBox" v-if="popupTap=='message'">
    <div class="dg-pop-hd-st2-box">
      <div class="pop-h1">쪽지보내기</div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close" @click="closePopup">닫기</a></div>
    </div>
    <div class="pop-cont-box pop-memo-send-box">
      <div class="memo-hd-box st-add">
        <span class="memo-name">받는사람</span> 
        <!-- <strong>{{ userDetail.name }} </strong> -->
        <div class="user-name-list" >
          <ul class="iscroll3">
            <li v-for="(user, idx) in noteReceiveUserList" :key="user.userId" style="position: relative">
              <div class="btnPeopleDtl" style="position:initial" >
                <div v-if="user.userId">{{user.receiverName}} 님</div>
                <div v-if="user.roleId">{{user.roleName}} 전체</div>
                <div v-if="user.groupId">{{user.groupName}} 전체</div>
                <button v-if="user.userId" class="btn-del-sm" @click="onClickReceiveUserListDel(idx, user.userId, 'user')">삭제</button>
                <button v-if="user.roleId" class="btn-del-sm" @click="onClickReceiveUserListDel(idx, user.roleId, 'role')">삭제</button>
                <button v-if="user.groupId" class="btn-del-sm" @click="onClickReceiveUserListDel(idx, user.groupId, 'group')">삭제</button>
              </div>
            </li>
          </ul>
        </div>
        <button @click="popupTap='memberlistmulti'" class="btn-plus-sm" ><span class=""></span>추가</button> 
      </div>
      <div class="memo-body-box st-add">
        <textarea v-model="message" maxlength="1000" cols="30" rows="10" placeholder="메시지를 입력해주세요."></textarea>
        <div class="count-box"> {{ this.message.length }} / 1000</div>
      </div>
      <div class="btn-box"><a href="javascript:;" class="" @click="onClickSend">보내기</a></div>
    </div>
  </div>


  <div class="dg-pop-layer pop-wd-wide st-memo-send on mb-st-popbtm" id="popDivisionCountBox" v-if="popupTap=='list'">
    <div class="dg-pop-hd-st2-box st-flex">
      <div class="pop-h1">{{ groupDetail.parentId == 1? '분과':'동호회'}}인원 <span class="txts">{{ groupDetail.groupName }}</span></div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
    </div>
    <div class="pop-cont-box pop-divisioncount-box">
      <div class="row-box">
        <div class="tit-box"><span class="tit-line">역대 운영위원</span></div>
        <div class="txt-box">
          <ul>
            <li><div class="tit-s">위원장</div> <p v-for="generation in chairman" :key="generation.leaderId">{{ generation.leaderName }}({{ generation.tenure }})</p><p></p></li>
            <li><div class="tit-s">간사</div> <p v-for="generation in secretary" :key="generation.leaderId">{{ generation.leaderName }}({{ generation.tenure }})</p></li>
          </ul>
        </div>
      </div>
      <div class="row-box">
        <div class="tit-box"><span class="tit-line">총 <strong>{{ totalElements }}</strong> 명</span></div>
        <div class="data-list-box ">
          <ul class="iscroll3">
            <li v-for="user in userList" :key="user.userId" style="position: relative">
              <a href="javascript:;" class="btnPeopleDtl" style="position:initial" @click="onClickDetail(user.userId)">
                <em class="icon-new" style="left: 0;top: 2px;" v-if="isNew(user.createdAt)"></em>
                {{user.name}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- 회원 검색 리스트 -->
  <div class="dg-pop-layer pop-wd-wide st-memo-send on" id="popDivisionCountBox" v-if="popupTap=='memberlist'">
    <div class="dg-pop-hd-st2-box st-member-sch">
      <div class="pop-h1"> 회원조회</div>
      <div class="search-box">
        <input type="text" v-model="param.searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd240 mr10" @keydown="onKeydown">
        <a href="javascript:;" class="btn-s" @click="onClickBeforeSearch">검색</a>
      </div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopup">닫기</a></div>
    </div>
    <div class="pop-cont-box pop-divisioncount-box">
      <div class="row-box">
        <div class="board-search-wrap st5">
          <div class="rs-cnt-box">총 <em class="st2">{{ memberList.totalElements }}</em> 명</div>
          
        </div>
 
        <div class="tb-list-li-box iscroll3 pc-only">
          <ul class="tb-tit-box">
            <li>번호</li>
            <li>사진</li>
            <li>이름</li>
            <li>분과</li>
            <li>동호회</li>
            <li>선택</li>
          </ul>
          <ul class="tb-txt-box" v-for="(member, idx) in memberList.content" :key="idx"  >
            <li>{{ idx + 1 + ((pagination.currentPage-1) * pagination.pageSize) }}</li>
            <li>
              <img class="member-list-box" v-if="member.photoFile==null" src="../../assets/front/images/icon/member.jpg"/>
              <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
            </li>
            <li>
              <a href="javascript:;" class="btnPeopleDtlOver">
                <span class="icon-new st2" v-if="isNew(member.createdAt)"></span> {{ member.name }}
              </a>
            </li>
            <li>{{ memberSubdivision(member.departmentGroup) }}</li>
            <li>{{ memberClub(member.clubGroup )}}</li>
            <li><a href="javascript:;" @click="onClickTargetUser(member)" class="btn-file-up wd-sm">선택</a></li>
          </ul> 
        </div>  
        <div class="mb-only member-mb-list iscroll3 h360">
          <div class="row-box" v-for="(member, idx) in memberList.content" :key="idx"  >
            <div class="profile-box">
              <img class="member-list-box" v-if="member.photoFile==null" src="../../assets/front/images/icon/member.jpg"/>
              <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
            </div>
            <div class="text-box">
              <div class="name-box">
                <a href="javascript:;" class="btnPeopleDtlOver" :style="idx===overIdx? 'font-weight:bold':''">
                  <span class="icon-new st2" v-if="isNew(member.createdAt)"></span> {{ member.name }}
                </a>
              </div>
              <div class="part-box">
                {{ memberSubdivision(member.departmentGroup) }}
                {{ memberClub(member.clubGroup )}}
              </div>
              <div class="btn-select-box"><a href="javascript:;" @click="onClickTargetUser(member)" class="btn-file-up wd-sm">선택</a></div>
            </div>
          </div>
        </div>
        <!-- class : pagination (S) -->
        <DgPagination v-model="pagination" :value="memberList" @pageSelected="pageSelected"/>
        <!-- class : pagination (E) -->
      </div>  
    </div>
  </div>
  <!-- 회원 검색 리스트 -->
  


  <!-- 회원 검색 리스트 (그룹별 추가) -->
  <div class="dg-pop-layer pop-wd-wide st-memo-send on" id="popDivisionCountBox" v-if="popupTap=='memberlistmulti'">
    <div class="dg-pop-hd-st2-box st-member-sch">
      <div class="pop-h1"> 회원조회</div>
      <div class="search-box"> 
        <input type="text" v-model="param.searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd240 mr10" @keydown="onKeydown">
        <a href="javascript:;" class="btn-s" @click="onClickBeforeSearch">검색</a>
      </div>
      <div class="btn-close-box"><a href="javascript:;" class="btn-close dg-pop-close" @click="closePopupMemberSearch">닫기</a></div>
    </div>
    <div>
      <ul class="dg-pop-tab-box">
        <li :class="{ active : searchGroupType !== 'group'}"><a href="javascript:;" @click="onClickBeforeSearch">회원</a></li>
        <li :class="{ active : searchGroupSubType === 'role'}"><a href="javascript:;" @click="searchMemberGroupList('role')">운영진</a></li>
        <li :class="{ active : searchGroupSubType === 'group'}"><a href="javascript:;" @click="searchMemberGroupList('group')">분과그룹</a></li>
        <li :class="{ active : searchGroupSubType === 'club'}"><a href="javascript:;" @click="searchMemberGroupList('club')">동호회그룹</a></li>
      </ul>
    </div>
    <div class="pop-cont-box pop-divisioncount-box">
      <div class="row-box">
        <div class="board-search-wrap st5">
          <div v-if="searchGroupType != 'group'" class="rs-cnt-box">총 <em class="st2">{{ memberList.totalElements }}</em> 명</div>
          <div v-if="searchGroupType == 'group'" class="rs-cnt-box">총 <em class="st2">{{ memberGroupList.length }}</em> 그룹</div>
        </div>
        <div class="tb-list-li-box iscroll3 h360"  v-if="searchGroupType != 'group'">
          <ul class="tb-tit-box">
            <li>번호</li>
            <li>사진</li>
            <li>이름</li>
            <li>분과</li>
            <li>동호회</li>
            <li>선택</li>
          </ul>
          <ul class="tb-txt-box" v-for="(member, idx) in memberList.content" :key="idx"  >
            <li class="pc-only">{{ idx + 1 + ((pagination.currentPage-1) * pagination.pageSize) }}</li>
            <li class="img-people">
              <img class="member-list-box" v-if="member.photoFile==null" src="../../assets/front/images/icon/member.jpg"/>
              <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
            </li>
            <li class="name">{{ member.name }}
              <div class="mb-only name2">
                {{ memberSubdivision(member.departmentGroup) }} {{ memberClub(member.clubGroup )}}
              </div>
            </li>
            <li class="pc-only">{{ memberSubdivision(member.departmentGroup) }}</li>
            <li class="pc-only">{{ memberClub(member.clubGroup )}}</li>
            <li><input type="checkbox" v-model="selectCheckBox" :value="member" class="chk-st1 st-none" :id="'chk'+member.userId" :key="member.userId" ><label :for="'chk'+member.userId">선택</label></li>
          </ul> 
        </div>  
        <div class="tb-list-li-box iscroll3 h360 st-group"  v-if="searchGroupType == 'group'">
          <ul class="tb-tit-box">
            <li>그룹명</li>
            <li>선택</li>
          </ul> 
          <ul class="tb-txt-box" v-for="(member, idx) in memberGroupList" :key="idx"  >
            <li v-if="searchGroupSubType == 'role'">{{ member.roleName }} ({{ member.count }}) </li>
            <li v-if="searchGroupSubType == 'role'"><input type="checkbox" v-model="selectCheckBox" :value="member" class="chk-st1 st-none" :id="'chk010'+member.roleId" ><label :for="'chk010'+member.roleId">선택</label></li>
            <li v-if="searchGroupSubType != 'role'">{{ member.groupName }}  ({{ member.count }}) </li>
            <li v-if="searchGroupSubType != 'role'"><input type="checkbox" v-model="selectCheckBox" :value="member" class="chk-st1 st-none" :id="'chk010'+member.groupId" ><label :for="'chk010'+member.groupId">선택</label></li>
          </ul> 
        </div>  
        <div class="pagination-btn-box">
          <!-- class : pagination (S) -->
          <div class=""  v-if="searchGroupType != 'group'">
          <DgPagination v-model="pagination" :value="memberList" @pageSelected="pageSelected"/>
          </div>
          <!-- class : pagination (E) -->
          <div class="board-btn-box mb100"><a href="javascript:;" @click="onClickSelectSend">추가</a></div>
        </div> 
      </div>  
    </div>
  </div>
  <!-- 회원 검색 리스트 -->

</div>
</template>

<script>
import userApi from "@/api/UserApi";
import groupApi from "@/api/GroupApi";
import noteApi from "@/api/NoteApi";
import generationLeaderApi from "@/api/GenerationLeaderApi";
import DgPagination from '@/views/component/DgPagination';

export default {
  name: "CommonPopup",
  components:{
    DgPagination
  },
  data(){
    return {
      photoDialog: false,
      popupTap: this.tap,
      groupDetail: {},

      generationList: [],
      chairman:[],
      secretary:[],
      userList: [],
      managerItems:[],
      departmentItems:[],
      clubItems:[],
      selectCheckBox:[],
      totalElements: 0,
      userDetail: {},
      userDetailDepartment: '',
      userDetailClubs: '',
      message: '',

      param: {
        searchColumn: 'all',
        searchKeyword: null,
        searchRole:'',
        searchDepartment:'',
        searchClub:'',
        page: 1
      },

      memberList:[],
      memberGroupList:[],
      pagination:{
        currentPage: 1,
        pageSize:5,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },
      targetUser:{},
      searchGroupType:null,
      searchGroupSubType:null,
      noteReceiveUserList:[], 
      userName:null,
    }
  },
  created(){
    if(this.userId != null) {
      this.getUserDetail()
    }else if(this.groupId != null){
      this.getGroupUsers()
    }

    if(this.groupId!=null) {
      groupApi.getGroup(this.groupId).then(data=>{
        this.groupDetail = data;
      })

      generationLeaderApi.list(this.groupId).then(data => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].groupPosition == 'chairman') {
            this.chairman.push(data[i])
          } else {
            this.secretary.push(data[i])
          }
        }
        this.generationList = data;
      });
    } 
  }, 
  mounted(){
    if(this.popupTap == "detail"){
      this.onClickBeforeSearch()
    }
    if(this.popupTap == "message" && this.memberList.length == 0 ){
      this.onClickBeforeSearch()
    }
  },
  props:{
    tap:{
      type:String,
      default:null,
    },
    groupId:{
      type:Number
    },
    userId:{
      type:String
    },
    targetUserInfo:{
      type:String
    }
  },
  methods:{  
    photoScale(){
      this.photoDialog = true;
    },
    popMessage(){

      if(this.userDetail){
        this.popupTap = 'message'
        let sendData = { 'userId':  this.userId
              , 'receiverName': this.userName
              , 'roleId' : null
              , 'roleName': null
              , 'groupId': null
              , 'groupName': null
        }
        this.noteReceiveUserList.push(sendData); 
      }
    },
    onClickReceiveUserListDel(idx, selectId, typ){
      this.noteReceiveUserList.splice(idx,1);
      if(typ == "user"){
        const index = this.selectCheckBox.findIndex((value) => value.userId === selectId);
        this.selectCheckBox.splice(index, 1);
      }
      if(typ == "group"){
        const index = this.selectCheckBox.findIndex((value) => value.groupId === selectId);
        this.selectCheckBox.splice(index, 1);
      }
      if(typ == "role"){
        const index = this.selectCheckBox.findIndex((value) => value.roleId === selectId);
        this.selectCheckBox.splice(index, 1);
      }
      
    },
    onClickSelectSend(){
      if(this.selectCheckBox =="" || this.selectCheckBox.length==0 || this.noteReceiveUserList == null){
        return alert('회원 및 그룹을 선택해주세요.')
      }else{
        // 비우고 담기
        //this.noteReceiveUserList.splice(0);
        this.selectCheckBox.forEach(data => { 
          let sendData = { 'userId':  (data.userId ?? data.userId) || null 
            , 'receiverName': (data.name ?? data.name) || null 
            , 'roleId' : (data.roleId ?? data.roleId) || null  
            , 'roleName':  (data.roleName ?? data.roleName) || null  
            , 'groupId': (data.groupId ?? data.groupId) || null   
            , 'groupName': (data.groupName ?? data.groupName) || null
          } 
          let index = -1
          if(data.userId){
            index = this.noteReceiveUserList.findIndex(function(item){ return item.userId === data.userId; })
          }
          if(data.roleId){
            index = this.noteReceiveUserList.findIndex(function(item){ return item.roleId === data.roleId; })
          }
          if(data.groupId){
            index = this.noteReceiveUserList.findIndex(function(item){ return item.groupId === data.groupId; })
          } 
          if(index == -1){
            this.noteReceiveUserList.push(sendData); 
          } 
        })
        this.closePopupMemberSearch();
      }
    },
    searchMemberGroupList(typVal){
      const params = {size:1000};
      this.setParams(params);
      this.searchGroupSubType = typVal;
      if(typVal == "role"){
        groupApi.mangerNameList().then(data => {
          this.memberGroupList = data;
          this.searchGroupType = "group";
          this.setPaging(data);
        });
      }
      if(typVal == "group"){
        groupApi.groupNameList(1).then(data => {
        this.memberGroupList = data;
        this.searchGroupType = "group"; 
        this.setPaging(data);
      });
      }
      if(typVal == "club"){
        groupApi.groupNameList(2).then(data => {
          this.memberGroupList = data;
          this.searchGroupType = "group";
          this.setPaging(data);
        });
      }
    },
    getUserDetail(){
      this.userDetailClubs=''

      userApi.get(this.userId).then((data) =>{
        this.userDetail = data 
        this.userName = this.userDetail.name

        if(this.popupTap == "message"){
          //쪽지보내기 일경우 받는사람 추가 
          this.popMessage();
        }
        this.userDetailDepartment = data.departmentGroup[0].groupName
 

        if(data.clubGroup != null) {
          for (let i = 0; i < data.clubGroup.length; i++) {
            this.userDetailClubs += data.clubGroup[i].groupName
            if (i < data.clubGroup.length - 1) {
              this.userDetailClubs += ", "
            }
          }
        }
      })
    },
    getGroupUsers(){
      //그룹회원조회
      const params = {
        size:1000
      }
      //params.searchGroup = this.groupId
      params.searchGroup = this.groupId
      params.page = 1
      userApi.groupUsers(params).then((data) => {
        this.userList = data.content
        this.totalElements = data.totalElements
      })
    },
    getMemberList(){
      //회원조회
      const params = {size:1000};
      this.setParams(params);
      userApi.list(params).then(data => {
        this.memberList = data;
        this.setPaging(data);
        this.searchGroupType = "";
        this.searchGroupSubType = "";
      })
    },
    setParams(params){
      params.fromDate = null;
      params.toDate = null;
      params.searchState = 'STATE_NORMAL'
      params.size = this.pagination.pageSize
      params.page = this.pagination.currentPage
    },
    setPaging(data){
        let totalElements = 0;
        if(this.searchGroupType == "group"){
          totalElements = data.length;
        }else{
          totalElements = data.totalElements;
        }
        this.pagination.totalElements=totalElements
        let totalPages = Math.floor(totalElements / this.pagination.pageSize) + ((totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
    },
    onClickBeforeSearch(){
      this.pagination={
        currentPage: 1,
        pageSize:10,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      delete this.param.searchColumn;
      this.onClickSearch();
    },
    onClickSearch(){
      const params = {...this.param}; 
      params.fromDate = null;
      params.toDate = null;
      params.searchState = 'STATE_NORMAL'
      params.size = this.pagination.pageSize
      params.page = this.pagination.currentPage
      userApi.list(params).then(data => {
        this.memberList = data;
         
        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;
        this.searchGroupType = "";
        this.searchGroupSubType = "";
      })
    }, 
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickBeforeSearch();
      }
    },
    pageSelected(pagination){
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    onMouseOver(idx){
      this.overIdx=idx;
    },
    onMouseLeave(){
      this.overIdx=null;
    },
    onClickTargetUser(targetUser){  
      this.$emit('click:targetUserSelect',targetUser);
      this.closePopup();
    },
    closePopup(){
      if(this.tap == "detail" && this.popupTap == "message"){
        this.message="";
        if(this.noteReceiveUserList) this.noteReceiveUserList.splice(0);
        if(this.selectCheckBox) this.selectCheckBox.splice(0);
      }
      if(this.tap != this.popupTap){
        this.popupTap = this.tap
      }else {
        this.$emit('click:bgDimClose', false)
      }
    }, 
    closePopupMemberSearch(){
      this.popupTap = 'message'
    },  
    onClickDetail(val){
      this.popupTap = 'detail'
      this.userId = val
      this.getUserDetail()
    },
    onClickSend(){
      if(this.message.length==0){
        return alert('내용을 입력해주세요.')
      }
      if(this.message.length>1000){
        return alert('1000자를 초과 할수 없습니다.')
      }
      if(this.noteReceiveUserList.length==0){
        return alert('받는사람을 추가해주세요.')
      }
      let params = {
        'noteContent':this.message,
        'noteReceiveUsers':this.noteReceiveUserList
      }  
      noteApi.save(params).then(()=>{
        alert('쪽지를 전송하였습니다.');
        this.message="";
        if(this.noteReceiveUserList) this.noteReceiveUserList.splice(0);
        if(this.selectCheckBox) this.selectCheckBox.splice(0);
        this.popupTap = 'message'
        this.closePopup();
      })
    },
    phoneFormatter(val) {
      if (val != null) {
        if (val.length == 11) {
          return val.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (val.length == 8) {
          return val.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          if (val.indexOf("02") == 0) {
            return val.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          } else {
            return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
      }
    },
    isNew(val) {
      //오늘로부터 6개월 내에 가입한 사람은 new 아이콘
      let moment = require('moment')
      let createdAt = moment(val)

      let calculDate = moment().subtract(6,'M')
      return createdAt > calculDate? true:false
    },
    memberClub(val){
      if(val!=null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }
    },
    memberSubdivision(val){
      if(val!=null) {
        let subdivisionLength = val.length
        let userSubdivision = subdivisionLength > 0 ? val[0].groupName : ''
        return userSubdivision
      }
    },
  },
  watch:{
  }
}
</script>

<style scoped>
.member-mb-list.h360{height: 360px;overflow: hidden;overflow-y: auto;}
.member-mb-list.h360 .row-box{position: relative;}
.member-mb-list.h360 .btn-select-box{position: absolute;right:1rem;top:1rem;}
.member-mb-list.h360 .btn-select-box .btn-file-up.wd-sm{background: #fff;height: 30px;line-height: 28px;font-size: .8rem;width:64px}
.tb-list-li-box{overflow-y:auto;height: 420px;margin-bottom: 20px;}
.tb-list-li-box.h360{height: 360px;}
.tb-list-li-box .tb-tit-box{display: flex; justify-content: space-between;    align-items: center;    background: #f8f8f8;
    height: 55px;    line-height: 55px;    border-bottom: 1px solid #dfdfdf;    font-weight: 500;    font-size: 17px;    color: #333333;border-top:1px solid #333333}
.tb-list-li-box .tb-txt-box{display: flex; justify-content: space-between;    align-items: center;    padding: 12px 0 12px 0;
    min-height: 44px;    box-sizing: border-box;    border-bottom: 1px solid #eeeeee;}
.tb-list-li-box li{text-align:center}
.tb-list-li-box li:first-child{width:10%}
.tb-list-li-box li:nth-child(2){width:14%}
.tb-list-li-box li:nth-child(3){width:15%}
.tb-list-li-box li:nth-child(4){width:23%}
.tb-list-li-box li:nth-child(5){width:23%}
.tb-list-li-box li:nth-child(6){width:15%}

.tb-list-li-box.st-group li:first-child{width: 50%;text-align: left;padding-left:30px}
.pagination-btn-box{position: relative;}
.pagination-btn-box .board-btn-box{position: absolute;right:0;top:-10px;}

</style>