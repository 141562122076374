import axios from 'axios';
// import {clarify} from "../utils/clear";

class GenerationLeaderApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }

    list(groupId) {
        // const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/group/${groupId}/generation/leaders`, {}).then(response=>response.data);
    }

    get(leaderId) {
        return axios.get(`${this.endpoint}/group/generation/leader/${leaderId}`, {}).then(response=>response.data);
    }

    save(leader) {
        return axios.post(`${this.endpoint}/group/generation/leader/save`, leader).then(response=>response.data);
    }

    delete(leaderId) {
        return axios.delete(`${this.endpoint}/group/generation/leader/delete/${leaderId}`, {}).then(response=>response.data);
    }

    order(leaderId, newOrderNo) {
        return axios.post(`${this.endpoint}/group/generation/leader/${leaderId}/order`, {newOrderNo}).then(response=>response.data);
    }

}

export default new GenerationLeaderApi();
