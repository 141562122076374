import axios from 'axios';
import {clarify} from "@/utils/clear";

class NoteApi {
    constructor() {
        this.endpoint = process.env.VUE_APP_BASE_API;
    }

    sendList(body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/note/send/list`, {params}).then(response=>response.data);
    }

    receiveList(body) {
        const params = new URLSearchParams(clarify(body));
        return axios.get(`${this.endpoint}/note/receive/list`, {params}).then(response=>response.data);
    }

    get(noteId) {
        return axios.get(`${this.endpoint}/note/get/${noteId}`, {}).then(response=>response.data);
    }

    getReceive(noteId) {
        return axios.get(`${this.endpoint}/note/get/${noteId}?isReceive=false`, {}).then(response=>response.data);
    }

    save(note) {
        return axios.post(`${this.endpoint}/note/send`, note).then(response=>response.data);
    }

    deleteSend(noteId) {
        return axios.delete(`${this.endpoint}/note/delete/send/${noteId}`, {}).then(response=>response.data);
    }

    deleteReceive(noteId) {
        return axios.delete(`${this.endpoint}/note/delete/receive/${noteId}`, {}).then(response=>response.data);
    }

    deleteMultiSend(noteIds) {
        return axios.delete(`${this.endpoint}/note/delete/multi/send/?${noteIds}`, {} ).then(response=>response.data);
    }

    deleteMultiReceive(receiveIds) {
        return axios.delete(`${this.endpoint}/note/delete/multi/receive/?${receiveIds}`, {}).then(response=>response.data);
    } 
}


export default new NoteApi();
